@tailwind base;
@tailwind components;
@tailwind utilities;
/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');
.font-poppins {
  font-family: "Poppins", sans-serif;
}
input,
input::placeholder {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
*:not(.no-font-change) {
  font-family: "Poppins", sans-serif !important;

}
.no-font-change {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-table-row-header .rs-table-cell {
  font-weight: 800;
}

/*table th,table td  {*/
/*    font-size: 10px !important;*/
/*    width: 250px !important; !* set a fixed width for each column *!*/
/*    max-width: 400px !important; !* set a maximum width to prevent columns from expanding *!*/
/*    !*white-space: ; !* prevent text wrapping in cells *!*!*/
/*}*/
/*table td,*/
/*.c-input {*/
/*  width: 300px !important;*/
/*  font-size: 10px !important; !* set the width to your desired value *!*/
/*}*/
.input {
  border-radius: 2px !important;
}
.modal-box {
  border-radius: 5px !important;
}
td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-align: center !important;
}
button {
  text-transform: capitalize !important;
}
/* width */
::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(226, 226, 226);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(219, 219, 219);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #758283;
}
.select {
  border-radius: 2px !important;
}

.label-text{
  font-weight: bold !important;
}
select{
  font-weight: normal !important;
}
.c-font1{
  font-size: 12px;
}
.swal2-title {
  font-size: 20px !important;
}
.swal2-actions{
  width: -webkit-fill-available !important;

}
/*.c-table1 table, th, td {*/
/*  border: 1px solid gray;*/
/*  border-collapse: collapse;*/
/*}*/

/*ul li::before {*/
/*  content: '\2022'; !* Unicode character for a solid circle *!*/
/*  margin-right: 0.5em; !* Add some spacing between the circle and the list item text *!*/
/*}*/
ol {
  list-style-position: inside;
  list-style-type: decimal;
  padding: 0 1em;
}

/*ol li*/
/*{*/
/*  margin: 0 0 0 1em;*/
/*  padding: 0 0 0 1em;*/
/*  text-indent: -17.7px;*/
/*}*/
ul {
  list-style-position: inside;
  list-style-type: disc;
  padding: 0 1em;
}

ul li {
  margin: 0 0 0 1em;
  padding: 0 0 0 1em;
  /*text-indent: -1.3em;*/
  text-indent: -19.7px;
}
.c-border{
  border: 1px black solid;
  padding:2px;
}
.option-margin{
  margin-left: 12px;
}
.customQuestionMathAJAX p{
  flex-grow: inherit;
}
